import React from 'react';
import { Card } from 'semantic-ui-react';

export const Word = ({ isNewbie }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Что готовить для служения словом?</Card.Header>
    </Card.Content>
    <Card.Content>
      <p>Заранее выучи наизусть отрывок из Библии – минимум 10 стихов.</p>
      <p>
        Заранее выучи наизусть стихотворение — не меньше 4 куплетов,
        рассказывать с выражением. Пересказ песни вместо стихотворения — не
        зачёт.
      </p>
      {!isNewbie && (
        <p>
          У нас хранятся списки с прошлых лет, так что ты не можешь повторить
          то, что сдавал(а) раньше.
        </p>
      )}
    </Card.Content>
  </Card>
);
