export const YEAR = 2024;
export const PRICE = 450;
export const ADDRESS = '8802 NW 9th Ave, Vancouver, WA 98665';
export const BEGIN_DATE = '17 июня (понедельник) в 9:00АМ';
export const END_DATE = '30 июня (воскресенье) в 4:00PМ';
export const EXAM_DATE = '16 июня в 12:00PМ';

export const RECOMMENDED_SONGS_URL = 'https://docs.google.com/document/d/108rs1Tda6rPWQ8M8o_GKFkWys4SbWawapbPHJD6M1qQ/edit?usp=sharing';
export const RECOMMENDATION_LETTER_URL = 'https://drive.google.com/file/d/0B_RVpLovVBS8R2x0SWNNT2RHNEU/view?usp=sharing&resourcekey=0--AQobGkcMsPAUQAvDga-7g';
export const REGISTRATION_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdLtSM54KU_pIrmL-r-u7uygYo6HH-dljx3A2I4IGS49rZhFQ/viewform?usp=sf_link';

export const DEACONS_CONTACT = [
  {
    name: 'Валентин Татаренко',
    linkNum: '+13603560523',
    displayNum: '+1 (360) 356-0523',
  },
  {
    name: 'Сергей Марушенко',
    linkNum: '+13605535921',
    displayNum: '+1 (360) 553-5921',
  },
];
