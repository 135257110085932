import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Card } from 'semantic-ui-react';
import { Box } from 'theme-ui';
import { ADDRESS, BEGIN_DATE, DEACONS_CONTACT, END_DATE, EXAM_DATE, PRICE } from '../lib/constants';

export const WhereAndWhen = () => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Где, когда и сколько?</Card.Header>
    </Card.Content>
    <Card.Content>
      <Box pb={3}>
        Место проведения курсов будет по адресу {ADDRESS}.
      </Box>
      <Box pb={3}>Начало курсов {BEGIN_DATE}.</Box>
      <Box pb={3}>Конец курсов {END_DATE}.</Box>
      <Box pb={3}>
        Вступительный экзамен и технический зачёт {EXAM_DATE}.
      </Box>
      <Box pb={3}>Стоимость обучения на курсах – ${PRICE}.</Box>
      <Box pb={3}>
        Оплатить можно наличными (cash) или выслать деньги на PayPal, Zelle или
        Cash App (куда высылать – объявим потом).
      </Box>
      <Box pb={3}>
        Если ты не знаешь, где будешь останавливаться, реши этот вопрос с
        дьяконами:
        {DEACONS_CONTACT.map(x => (
          <Fragment key={x.linkNum}>
            {' '}{x.name}{' '}
            <Box
              as='a'
              href={`tel:+${x.linkNum}`}
              sx={{ display: 'inline', whiteSpace: 'nowrap' }}
            >
              {x.displayNum}
            </Box>
          </Fragment>
        ))}
        .
      </Box>
    </Card.Content>
  </Card>
);
