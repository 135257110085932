import React from 'react';
import { Card, Divider } from 'semantic-ui-react';

export const Tech = ({ isNewbie }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Что готовить для технического зачёта?</Card.Header>
    </Card.Content>
    <Card.Content>
      {isNewbie && (
        <p>
          Если ты едешь на курсы в первый раз и если тебя определят в самую
          первую группу (1-в), то тебе необязательно сдавать технический зачёт.
          Но если у тебя уже есть какой-то опыт с музыкой, тебя могут определить
          в группу выше уровнем, и там тебе уже точно нужно будет сдавать этот
          зачёт.
        </p>
      )}
      <p>
        Технический зачёт тебе нужно сдавать именно по той песне, которую ты
        выберешь для дирижирования.
      </p>
      <Divider />
      <p>Во-первых, тебе нужно уметь играть все партии одновременно.</p>
      <p>
        Потом, тебе нужно приготовиться петь по вертикали каждый аккорд в песне
        – от баса и вверх.
      </p>
      <p>
        И ещё – тебе нужно хорошо знать каждую партию, потому что мы попросим
        тебя одновременно играть одну партию и петь другую.
      </p>
    </Card.Content>
  </Card>
);
