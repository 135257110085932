import React from 'react';
import { REGISTRATION_URL } from '../lib/constants';

export const Register = () => (
  <div id='registration'>
    <iframe
      title='registration'
      src={REGISTRATION_URL}
      width='100%'
      height='300'
      frameBorder='0'
      marginHeight='0'
      marginWidth='0'
    >
      Loading...
    </iframe>
  </div>
);
