import React from 'react';
import { Card, Button, Divider } from 'semantic-ui-react';
import { Box } from 'theme-ui';
import { EXAM_DATE, RECOMMENDATION_LETTER_URL } from '../lib/constants';

import { levels } from './info.json';

export const WhatToPrepare = ({ isNewbie, level, setNewbie, setLevel }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Что нужно приготовить?</Card.Header>
    </Card.Content>
    <Card.Content>
      <Box pb={3}>
        Ты первый раз на курсах?
        <Button.Group style={{ marginLeft: '10px' }}>
          <Button
            color={isNewbie ? 'green' : null}
            onClick={() => setNewbie(true)}
          >
            Да
          </Button>
          <Button
            color={isNewbie === false ? 'green' : null}
            onClick={() => setNewbie(false)}
          >
            Нет
          </Button>
        </Button.Group>
      </Box>
      <Divider />
      {isNewbie && (
        <>
          <Box pb={3}>
            <p>Мы будем очень рады тебя видеть, добро пожаловать!</p>
            <p>
              Итак, если ты первый раз, то тебе обязательно нужно будет пройти
              вступительный экзамен.
            </p>
            <p>
              Не переживай, мы просто хотим проверить уровень твоих знаний и
              определить тебя в правильную группу, чтобы твоё время на курсах
              было максимально эффективным.
            </p>
            <p>
              На вступительном экзамене мы проверим твои знания по{' '}
              <b>теории музыки</b> и <b>сольфеджио</b>.
            </p>
            <p>
              Вступительный экзамен будет <b>{EXAM_DATE}</b>.
            </p>
          </Box>
          <Box pb={3}>
            <p>
              Так как ты будешь на курсах впервые, тебе нужно обязательно иметь
              с собой рекомендательное письмо, в котором должно быть написано,
              что церковь действительно посылает тебя обучаться.
            </p>
            <p>
              Вот{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={RECOMMENDATION_LETTER_URL}
              >
                пример такого письма
              </a>
              .
            </p>
          </Box>
          <Box pb={3}>
            Тебе нужно быть готовым к вокалу, дирижированию, служению словом и
            техническому зачёту. Подробности смотри внизу.
          </Box>
        </>
      )}
      {isNewbie === false && (
        <Box pb={3}>
          <p>Отлично! Рады тебя видеть ещё раз!</p>
          <p>В какую группу тебя перевели в прошлый раз?</p>

          <Box sx={{ textAlign: 'center' }}>
            {levels.map((item) => (
              <Button
                key={item.copy}
                style={{ marginBottom: '5px' }}
                onClick={() => setLevel(item.key)}
                color={level === item.key ? 'green' : null}
              >
                {item.copy}
              </Button>
            ))}
          </Box>
        </Box>
      )}
      {level !== null && isNewbie === false && (
        <>
          <Divider />
          <Box>
            Хорошо! Тебе нужно быть готовым к вокалу, дирижированию, служению
            словом и техническому зачёту. Также тебе нужно принести{' '}
            {level > 1 ? 'зачётную книжку' : 'сертификат'}. Подробности смотри
            внизу.
          </Box>
        </>
      )}
    </Card.Content>
  </Card>
);
