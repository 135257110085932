import React from 'react';
import { Card } from 'semantic-ui-react';
import { RECOMMENDED_SONGS_URL } from '../lib/constants';

export const Conducting = ({ level }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Что готовить для дирижирования?</Card.Header>
    </Card.Content>
    <Card.Content>
      <p>
        Заранее выбери одну хоровую песню из{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={RECOMMENDED_SONGS_URL}
        >
          списка рекомендованных
        </a>
        .
      </p>
      {level > 5 && (
        <p>
          Если ты будешь выпускником в этом году, то помимо хоровой, выбери ещё
          песню с оркестом из того же списка.
        </p>
      )}
      {level > 3 && (
        <p>
          Если у тебя когда-то был класс "Анализ формы", заранее приготовь
          аннотацию на выбранную песню.
        </p>
      )}
    </Card.Content>
  </Card>
);
