import React, { Fragment } from 'react';
import { Card } from 'semantic-ui-react';
import { Box } from 'theme-ui';
import { DEACONS_CONTACT, PRICE } from '../lib/constants';

export const Summary = ({ level, isNewbie }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Итак:</Card.Header>
    </Card.Content>
    <Card.Content>
      <p>Приготовь песню для вокала.</p>
      <p>Приготовь произведение для дирижирования.</p>
      <p>
        Приготовься к техническому зачёту
        {isNewbie && ' (если тебя определят в группу выше чем 1-в)'}.
      </p>
      {isNewbie && <p>Принеси рекомендательное письмо.</p>}
      <p>Выучи открывок из Библии – 10 стихов.</p>
      <p>Выучи стихотворение.</p>
      {!isNewbie && (
        <p>Принеси {level > 1 ? 'зачётную книжку' : 'сертификат'}.</p>
      )}
      <p>Принеси/пришли ${PRICE}.</p>
      <p>
        Реши вопрос с ночлегом – звони:
        {DEACONS_CONTACT.map(x => (
          <Fragment key={x.linkNum}>
            {' '}{x.name}{' '}
            <Box
              as='a'
              href={`tel:+${x.linkNum}`}
              sx={{ display: 'inline', whiteSpace: 'nowrap' }}
            >
              {x.displayNum}
            </Box>
          </Fragment>
        ))}
        .
      </p>
      <p>
        Зарегистрируйся <a href='#registration'>снизу</a>.
      </p>
    </Card.Content>
  </Card>
);
