import React from 'react';
import { Card } from 'semantic-ui-react';

export const Vocal = () => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Что готовить для вокала?</Card.Header>
    </Card.Content>
    <Card.Content>
      <p>
        Заранее найди одну или две песни для вокала (можно несколько, чтобы
        потом выбрать лучшее с учителем).
      </p>
      <p>
        Над этой песней ты будешь работать со своим учителем, а потом будешь
        петь её на экзамене.
      </p>
    </Card.Content>
  </Card>
);
