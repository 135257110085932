import React from 'react';
import { Card } from 'semantic-ui-react';
import { RECOMMENDATION_LETTER_URL } from '../lib/constants';

export const Documents = ({ level, isNewbie }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Какие нужны документы?</Card.Header>
    </Card.Content>
    <Card.Content>
      {isNewbie && (
        <p>
          Так как ты едешь первый раз, ещё раз напоминаем – тебе нужно
          рекомендательное письмо (
          <a
            href={RECOMMENDATION_LETTER_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            вот пример
          </a>
          ).
        </p>
      )}
      {!isNewbie && level < 2 && (
        <p>
          В прошлом году тебе выдали сертификат. Его должен подписать служитель,
          чтобы мы знали, что церковь действительно посылает тебя на курсы. Не
          забудь получить эту подпись и привезти этот сертификат с собой.
        </p>
      )}
      {!isNewbie && level > 1 && (
        <p>
          У тебя должна быть зачётная книжка, подписанная служителем – это для
          того, чтобы мы знали, что церковь действительно посылает тебя на
          курсы. Не забудь получить эту подпись и привезти зачётную книжку с
          собой.
        </p>
      )}
    </Card.Content>
  </Card>
);
