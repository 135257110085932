import React, { useState } from 'react';
import { Button, Container, Header } from 'semantic-ui-react';

import { WhereAndWhen } from './where-and-when';
import { WhatToPrepare } from './what-to-prepare';
import { Vocal } from './vocal';
import { Conducting } from './conducting';
import { Word } from './word';
import { Tech } from './tech';
import { Documents } from './documents';
import { Summary } from './summary';
import { Register } from './register';
import { YEAR } from '../lib/constants';

export const App = () => {
  const [level, setLevel] = useState(null);
  const [isNewbie, setIsNewbie] = useState(null);

  const setNewbie = (isNewbie) => {
    setLevel(null);
    setIsNewbie(isNewbie);
  };

  return (
    <Container style={{ padding: '20px 0' }}>
      <Header as='h2' textAlign='center'>
        Choir Academy {YEAR}
      </Header>

      <Button as='a' href='#registration' fluid primary>
        Регистрация внизу страницы
      </Button>
      <WhereAndWhen />
      <WhatToPrepare {...{ level, isNewbie, setLevel, setNewbie }} />
      {(isNewbie || level !== null) && (
        <>
          <Vocal />
          <Conducting {...{ level }} />
          <Word {...{ isNewbie }} />
          <Tech {...{ isNewbie }} />
          <Documents {...{ level, isNewbie }} />
          <Summary {...{ level, isNewbie }} />
        </>
      )}
      <Register />
    </Container>
  );
};
